import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "data ws_mr5"
};
const _hoisted_2 = {
  class: "ws_drawer_footer"
};
import { ref, watch, computed } from 'vue';
import CommonUpload from 'common/components/common-upload';
import { commonUploadUrl, addProductForm, updateProductForm, queryProductInfo } from '@/api';
import { CODE_ENUM } from 'common';
import { ElMessage } from 'element-plus';
export default {
  __name: 'product-form',
  props: ['visible', 'data'],
  emits: ['update:visible', 'success'],
  setup(__props, {
    emit: __emit
  }) {
    const $emit = __emit;
    const props = __props;
    const formData = ref({
      productImg: '',
      productName: '',
      productModel: '',
      productDesc: ''
    });
    const rules = {
      productName: [{
        required: true,
        message: '请输入'
      }],
      productModel: [{
        required: true,
        message: '请输入'
      }]
    };
    const defaultImg = ref('');
    const detailLoading = ref(false);
    const cancel = () => {
      $emit('update:visible', false);
      formRef.value.resetFields();
      defaultImg.value = '';
    };
    watch(() => props.visible, () => {
      if (props.visible) {
        queryDetail();
      }
    });
    const queryDetail = async () => {
      const id = props.data.id;
      if (!id) {
        return;
      }
      const res = await queryProductInfo({
        id
      });
      if ((res === null || res === void 0 ? void 0 : res.code) === CODE_ENUM.SUCCESS) {
        console.log(res.data);
        const {
          productName,
          productModel,
          productDesc,
          baseUrl,
          productImg
        } = res.data;
        if (productImg) {
          defaultImg.value = baseUrl + productImg;
        }
        formData.value = {
          productName,
          productModel,
          productDesc,
          productImg
        };
      }
    };
    const loading = ref(false);
    const formRef = ref();
    const handleSubmit = () => {
      formRef.value.validate(async valid => {
        if (valid) {
          loading.value = true;
          const fn = props.data.id ? updateProductForm : addProductForm;
          const res = await fn({
            ...formData.value,
            id: props.data.id
          });
          loading.value = false;
          if ((res === null || res === void 0 ? void 0 : res.code) === CODE_ENUM.SUCCESS) {
            ElMessage({
              message: props.data.id ? '修改产品成功' : '新建产品成功',
              type: 'success'
            });
            cancel();
            $emit('success');
          }
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_drawer = _resolveComponent("el-drawer");
      return _openBlock(), _createBlock(_component_el_drawer, {
        "model-value": props.visible,
        title: props.data.id ? '编辑产品' : '添加产品',
        size: "480px",
        "before-close": cancel
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_2, [_createVNode(_component_el_button, {
          loading: loading.value,
          type: "primary",
          onClick: handleSubmit
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        }, 8, ["loading"]), _createVNode(_component_el_button, {
          onClick: cancel
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_form, {
          model: formData.value,
          rules: rules,
          ref_key: "formRef",
          ref: formRef,
          "label-position": "left",
          "label-width": "80"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            prop: "productImg",
            label: "上传图片"
          }, {
            default: _withCtx(() => [_createVNode(_unref(CommonUpload), {
              modelValue: formData.value.productImg,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.value.productImg = $event),
              defaultImg: defaultImg.value,
              url: _unref(commonUploadUrl)
            }, null, 8, ["modelValue", "defaultImg", "url"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            prop: "productName",
            label: "产品名称"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formData.value.productName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formData.value.productName = $event),
              maxlength: "20",
              placeholder: "请输入产品名称"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            prop: "productModel",
            label: "型号"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formData.value.productModel,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formData.value.productModel = $event),
              maxlength: "50",
              placeholder: "请输入产品型号"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            prop: "productDesc",
            label: "备注"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formData.value.productDesc,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formData.value.productDesc = $event),
              maxlength: "500",
              placeholder: "请输入你的备注",
              rows: 4,
              type: "textarea"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])])]),
        _: 1
      }, 8, ["model-value", "title"]);
    };
  }
};